import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material';

import './theme/global.css';
import './i18n';

// import LoginPage from './pages/loginPage/loginPage.component';
// import AddJobFormPage from './pages/addJobFormPage/addJobFormPage.component';
// import JobDetailsPage from './pages/jobDetailsPage/jobDetailsPage.component';
// import JobListPage from './pages/jobListPage/jobListPage.component';
// import LandingPage from './pages/landingPage/landingPage.component';
import Placeholder from './pages/placeholder/placeholder.component';
import App from './app/app.component';
import { Path } from './enums';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Color } from './theme';

const router = createBrowserRouter([
  {
    path: Path.Main,
    element: <App />,
    children: [
      {
        path: Path.Main,
        element: <Placeholder />,
      },
      // {
      //   path: Path.Main,
      //   element: <LandingPage />,
      // },
      // {
      //   path: Path.Jobs,
      //   element: <JobListPage />
      // },
      // {
      //   path: Path.Login,
      //   element: <LoginPage />,
      // },
      // {
      //   path: Path.AddJob,
      //   element: <AddJobFormPage />,
      // },
      // {
      //   path: Path.JobDetails,
      //   element: <JobDetailsPage />,
      // },
    ],
  },
]);
const theme = createTheme({
  palette: {
    primary: {
      main: Color.Primary,
    },
  },
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.unregister();
reportWebVitals();
