import styled from '@emotion/styled';

import { Color, FontWeight, Margin, Media, ZIndex } from '../../theme';

export const Container = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: ${Margin.MobileEdge};
  overflow: hidden;
  position: relative;

  ${Media.Medium} {
    padding: ${Margin.TabletEdge};
  }
`;

export const Wrapper = styled.div`
  max-width: 900px;
  margin: 0 auto;
  position: relative;
`;

export const PhoneImg = styled.img`
  display: block;
  max-width: 330px;
  margin-top: 50px;

  ${Media.Medium} {
    margin-top: 0;
    max-width: unset;
    position: absolute;
    right: 100px;
    top: 0;
    z-index: ${ZIndex.BackgroundBasic};
    height: 100%;
  }
`;

export const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 330px;
  position: relative;
`;

export const Header = styled.h2`
  font-size: 2.3rem;
`;

export const Title = styled.h1`
  font-size: 7.5rem;
  font-weight: ${FontWeight.Bold};
  margin-top: 3rem;
`;

export const Text = styled.p`
  font-size: 1.6rem;
  margin: 3rem auto 0;
  text-align: center;
  max-width: 280px;
  line-height: 1.5;
`;

export const Link = styled.a`
  display: inline-block;
  color: ${Color.Primary};
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2px 3px;
  border-radius: 3px;

  @media screen and (min-width: 500px) {
    background-color: transparent;
  }
`;

export const BackgroundShape = styled.div`
  position: fixed;
  top: 50%;
  bottom: 0;
  right: 0;
  height: 50vh;
  width: 50vh;
  border-radius: 50%;
  background-color: ${Color.Primary};
  transform: translate(50%, -50%);
  z-index: ${ZIndex.BackgroundBasic};
`;

export const ForegroundShape = styled.div`
  display: none;

  ${Media.Medium} {
    display: block;
    position: absolute;
    top: 50px;
    right: -160px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: ${Color.Primary};
    z-index: calc(${ZIndex.BackgroundBasic} - 1);
  }
`;
