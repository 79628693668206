import { useTranslation } from 'react-i18next';

import PhonePic from './assets/phone.png';
import {
  Container,
  Wrapper,
  TextSection,
  Header,
  Title,
  Text,
  Link,
  BackgroundShape,
  ForegroundShape,
  PhoneImg,
} from './placeholder.styles';

export default function Placeholder() {
  const { t } = useTranslation();

  return (
    <Container>
      <BackgroundShape />
      <Wrapper>
        <TextSection>
          <ForegroundShape />
          <Header>
            {t('placeholder.header')}
          </Header>
          <Title>
            {t('placeholder.title')}
          </Title>
          <Text>
            {t('placeholder.text1')}
          </Text>
          <Text>
            {t('placeholder.text2')}
          </Text>
          <Text>
            {t('placeholder.call')} <Link href="tel:+48888915358">+48 888 915 358</Link>
            <br />
            {t('placeholder.sendEmail')} <Link href="mailto:kontakt@vertrust.pl">kontakt@vertrust.pl</Link>
          </Text>
        </TextSection>
        <PhoneImg src={PhonePic} />
      </Wrapper>
    </Container>
  );
}
